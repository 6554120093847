export default [
    {
      key: "branchCode",
      label: "Site ID",
      thStyle:{color:"white",textAlign: "center"}
    },
    { key: "branchName", label: "Site name", sortable: true , thStyle:{color:"white", width: "10% !important",textAlign: "center"}},
    { key: "ticketNo", label: "Job No.", sortable: true ,thStyle:{color:"white", width: "8% !important",textAlign: "center"}},
    { key: "openTicketDate", label: "Open date", sortable: true , thStyle:{color:"white", width: "8% !important",textAlign: "center"}},
    { key: "closeTicketDate", label: "Finish date", sortable: true , thStyle:{color:"white", width: "8% !important",textAlign: "center"}, },
    {
      key: "problemTypeName",
      label: "Problem type",
      sortable: false,
      thStyle: { width: "10% !important", textAlign: "center" ,color:"white"},
    },
    { key: "problemDetail", label: "Problem", sortable: false ,thStyle:{color:"white",width: "20% !important",textAlign: "center"}},
    {
      key: "problemSolving",
      label: "Detail",
      sortable: false,
      thStyle: { width: "15% !important", textAlign: "center", color:"white",width: "20% !important" },
    },
    {
      key: "assignTo",
      label: "Resolve by",
      sortable: false,
      thStyle: { width: "10% !important", textAlign: "center", color:"white" },
    },
    {
      key: "slaStatus",
      label: "SLA",
      sortable: false,
      thStyle: { width: "3% !important", textAlign: "center", color:"white" },
    },
    {
      key: "ticketStatus",
      label: "Status",
      sortable: false,
      thStyle: { width: "3% !important", textAlign: "center", color:"white" },
    },
  ];
  