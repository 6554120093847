<template>
  <div>
    <!--- Navigator-->
    <b-row>
      <b-col cols="3">
        <h3><b>รายงาน Tickets</b></h3>
      </b-col>
      <b-col class="d-flex justify-content-end align-items-center"> </b-col>
    </b-row>
    <!--- Navigator-->

    <b-row class="mt-1">
      <b-col cols="12">
        <!-- START SECTION FORM วันเดินทาง--->
        <b-card>
          <b-row class="mt-2">
            <b-col
              ><h4 class="text-primary">
                <b>ค้นหาด้วยข้อมูลพื้นฐาน</b>
              </h4></b-col
            >
          </b-row>

          <!--- START ROW วันที่ --->
          <b-row>
            <b-col md="3" class="">
              <label>วันที่เริ่มต้น (วันที่ปิดใบงาน)</label>
              <b-form-datepicker
                id="example-datepicker"
                locale="th-TH"
                v-model="dateStart"
                show-decade-nav
                class="mb-1"
                size="sm"
                
              />
            </b-col>
            <b-col md="3" class="">
              <label>วันที่สิ้นสุด</label>
              <b-form-datepicker
                id="example-datepicker2"
                locale="th-TH"
                v-model="dateEnd"
                show-decade-nav
                class="mb-1"
                size="sm"
                
              />
            </b-col>
            <b-col md="3" class="">
              <label>ผู้รับงาน (ช่าง)</label>
              <v-select
                id="brand"
                v-model="employeeSelected"
                label="fullname"
                :options="employeeOption"
                class="select-size-sm"
                :clearable="false"
              />
            </b-col>
            <b-col md="3" class="">
              <label>สถานะใบงาน</label>
              <v-select
                id="brand"
                v-model="ticketStatusSelected"
                label="title"
                :options="ticketStatusOption"
                :clearable="false"
                class="select-size-sm"
              />
            </b-col>
          </b-row>
          <!--- END วันที่ --->

          <b-row class="mt-2">
            <b-col
              ><h4 class="text-primary"><b>ค้นหาด้วยลูกค้า</b></h4></b-col
            >
          </b-row>

          <!--- START (ลูกค้า) --->
          <b-row>
            <b-col cols="3">
              <label>ลูกค้า</label>
              <v-select
                id="brand"
                v-model="ownerSelected"
                label="ownerName"
                :options="ownerOption"
                class="select-size-sm"
                :clearable="false"
                @input="onChangeCustomerOwner"
              />
            </b-col>
            <b-col cols="3" v-if="ownerSelected.id!==0">
              <label>Brand</label>
              <v-select
                id="brand"
                v-model="companySelected"
                label="companyName"
                :options="companyOption"
                class="select-size-sm"
                :clearable="false"
                @input="onChangeCustomerCompany"
              />
            </b-col>

            <b-col cols="3" v-if="ownerSelected.id!==0 && companySelected.id!==0">
              <label>บริษัท</label>
              <v-select
                id="brand"
                v-model="subCompanySelected"
                label="subCompanyName"
                :options="subCompanyOption"
                class="select-size-sm"
                :clearable="false"
                @input="onChangeCustomerSubCompany"
              />
            </b-col>
            <b-col cols="3" v-if="ownerSelected.id!==0 && companySelected.id!==0 && subCompanySelected.id!=0">
              <label>สาขา</label>
              <v-select
                id="brand"
                v-model="branchSelected"
                label="branchName"
                :options="branchOption"
                class="select-size-sm"
                :clearable="false"
              />
            </b-col>
          </b-row>
          <!--- END (ลูกค้า) --->

          <b-row class="mt-2">
            <b-col class="d-flex justify-content-start"><h3 class=" text-primary"><b>ใบงานทั้งหมด {{items.length  }} ใบงาน</b></h3></b-col>
            <b-col class="d-flex justify-content-end">
              <b-button
                variant="outline-primary"
                size="md"
                style="height: 35px"
                class="mr-2"
                
                @click="clearSearch"
              >
                <span> Clear Search</span>              
              </b-button>
              <b-button
                variant="outline-primary"
                size="md"
                style="height: 35px"
                class="mr-1"
                :disabled="isLoading || items.length == 0"
                @click="buildFieldExcel"
                title="คลิกปุ่มค้นหาข้อมูลก่อนทำการ Export"
              >
                <span v-if="!isLoading">
                   <div class="text-center">
                  <export-excel
                    :data="items"
                    :items="items"
                    :fields="json_fields"
                    worksheet="My Worksheet"
                    :name="fileName"
                  >
                    Export <feather-icon icon="FileTextIcon" />
                  </export-excel>
                   </div>
                </span>

                <span v-else>
                  <b-spinner label="Text Centered" small class="mr-1" />
                  Exporting...
                </span>
              </b-button>
              
              <b-button
                variant="primary"
                size="md"
                style="height: 35px"
                class="mr-1"
                :disabled="isLoading"
                @click="onSearch"
              >
                <span v-if="!isLoading"> ค้นหา </span>

                <span v-else>
                  <b-spinner label="Text Centered" small class="mr-1" />
                  Exporting...
                </span>
              </b-button>

            </b-col>
          </b-row>
        </b-card>
        <!-- END SECTION FORM วันเดินทาง--->
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <b-table
          style="font-size: 12px"
          class="shadow"
          small
          striped
          bordered
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"                    
          :busy="isLoading"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong> Loading...</strong>
            </div>
          </template>

          <template #cell(selected)="{ rowSelected }"></template>
          <template #cell(openTicketDate)="row">
            <center>{{ showDate(row.item.openTicketDate, "original") }}</center>
          </template>
          <template #cell(closeTicketDate)="row">
            <center>{{ showDate(row.item.closeTicketDate, "original") }}</center>
          </template>
        </b-table>
      </b-col>
      <b-col cols="2">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>

      <b-col cols="10" class="d-flex justify-content-end">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          variant="secondary"
          class="my-0"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BButton,
  BTable,
  BFormCheckbox,
  BInputGroupPrepend,
  BInputGroupAppend,
  BInputGroup,
  BFormInput,
  BFormTextarea,
  BFormDatepicker,
  BPagination,
  BTableSimple,
  BTr,
  BTh,
  BTd,
  BThead,
  BTbody,
  BFormRadioGroup,
  BSpinner,
  BFormSelect
} from "bootstrap-vue";
import vSelect from "vue-select";
import UserService from "@/services/user";
import CustomerService from "@/services/setting/customer";
import TicketReportService from "@/services/report/ticket";
import Field from "./field";
import moment from "moment";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BButton,
    BFormInput,
    BInputGroup,
    BTable,
    BFormCheckbox,
    BFormTextarea,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormDatepicker,
    BPagination,
    BTableSimple,
    BTr,
    BTh,
    BTd,
    BThead,
    BTbody,
    BFormRadioGroup,
    vSelect,
    BSpinner,
    BFormSelect
  },
  data() {
    return {
      perPage: 500,
      pageOptions: [500, 1000,1500,2000],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      employeeSelected: { fullname: "เลือกทั้งหมด", userId: 0 },
      ticketStatusSelected: { id: 0, title: "เลือกทั้งหมด", value: 0 },
      employeeOption: [],
      ticketStatusOption: [
        { id: 0, title: "เลือกทั้งหมด", value: 0 },
        { id: 4, title: "Inprogress & close", value: "inprogress-close" },
        { id: 1, title: "Inprogress", value: "inprogress" },        
        { id: 2, title: "Close", value: "close" },        
        { id: 3, title: "Cancel", value: "cancel" },
      ],
      ownerSelected: { ownerName: "เลือกทั้งหมด", id: 0 },
      ownerOption: [],
      companySelected: {},
      companyOption: [],
      subCompanySelected: {},
      subCompanyOption: [],
      branchOption: [],
      branchSelected: {},
      dateStart: "",
      dateEnd: "",
      isLoading: false,
      json_fields: {},
      fields: Field,
      items: [],
      
    };
  },

  created() {
    this.getUsers();
    this.getCustomerOwner();
  },
  computed:{
    fileName(){
      if(this.ticketStatusSelected.id==0){
        return "All Tickets.xls"
      }
      if(this.ticketStatusSelected.id==1){
        return "Inprogress Tickets.xls"
      }
      if(this.ticketStatusSelected.id==2){
        return "Closed Tickets.xls"
      }
      if(this.ticketStatusSelected.id==3){
        return "Cancel Tickets.xls"
      }
      if(this.ticketStatusSelected.id==4){
        return "Inprogress & close.xls"
      }
    },
    
    reportPermission () {
      const permission =  this.$store.getters["user/getPermissionPage"]("RP001")
      if(permission=="disable"){
        localStorage.removeItem("userData")
        window.location.href="/not-authorized"
      }
      return permission
    },
    customerRolePermission() {
      const permission =  this.$store.getters["user/getCustomerRolePermission"]("ST005-1");
        return permission
    },    
 
  },
  methods: {
    async onSearch() {
      
      let queryString = [];
      if(this.dateStart && !this.dateEnd){
        alert("กรุณาเลือกวันที่สิ้นสุด")
        return 
      }

      if(!this.dateStart && this.dateEnd){
        alert("กรุณาเลือกวันที่เริ่มต้น")
        return 
      }

      this.isLoading = true;
      if (this.dateStart && this.dateEnd) {
        queryString.push(`dateStart=${this.dateStart}&dateEnd=${this.dateEnd}`);
      }

      if (this.ticketStatusSelected.value !== 0) {
        queryString.push(`ticketStatus=${this.ticketStatusSelected.value}`);
      }

      if (this.employeeSelected.userId !== 0) {
        queryString.push(`assignTo=${this.employeeSelected.userId}`);
      }

      //Customer Search
      if(this.ownerSelected.id && this.ownerSelected.id !== 0){
        queryString.push(`ownerId=${this.ownerSelected.id}`);
      }

      if(this.companySelected.id && this.companySelected.id !== 0){
        queryString.push(`companyId=${this.companySelected.id}`);
      }

      
      if(this.subCompanySelected.id && this.subCompanySelected.id !== 0){
        queryString.push(`subCompanyId=${this.subCompanySelected.id}`);
      }

      if(this.branchSelected.id && this.branchSelected.id !== 0){
        queryString.push(`customerId=${this.branchSelected.id}`);
      }

      const joined = queryString.join("&");

      console.log("joined: " , joined)

      const result = await TicketReportService.GetTicketReport(joined);
      // this.items = result.data.data;
      this.items = result.data.data.map(item => {
        return {
          ...item,
          align: 'center'
        }
      })

     

      this.totalRows = this.items.length;
      this.buildFieldExcel()
      this.isLoading = false;
    },

    async getUsers() {
      const result = await UserService.GetUserList({});
      this.employeeOption.push({ fullname: "เลือกทั้งหมด", userId: 0 });
      this.employeeOption = [...this.employeeOption, ...result.data.data];
      this.employeeSelected = { fullname: "เลือกทั้งหมด", userId: 0 };
    },
    async getCustomerOwner() {
      const result = await CustomerService.GetCustomerOwnerList({});
      this.ownerOption.push({ ownerName: "เลือกทั้งหมด", id: 0 });
      this.ownerOption = [...this.ownerOption, ...result.data.data];

      //IF Have Role Permission For Customer
      if(this.customerRolePermission){
        if(Object.keys(this.customerRolePermission).length > 0 && this.customerRolePermission.ownerId !==0){    
          this.ownerOption = this.ownerOption.filter(option=>option.id == this.customerRolePermission.ownerId && option.id !==0)        
        }
      }


      if (result.data.data.length > 0) {
        let item = this.ownerOption[0];
         this.ownerSelected = item
        this.onChangeCustomerOwner(item);
      } else {
        this.ownerSelected = {};
      }
    },

    async getCustomerCompany(ownerObj) {
      const result = await CustomerService.GetCustomerCompanyList(ownerObj.id);      
      this.companyOption = [{id:0, companyName:"==== ทั้งหมด ===="}, ...result.data.data];

      //IF Have Role Permission For Customer
      if(this.customerRolePermission){      
        if(Object.keys(this.customerRolePermission).length > 0 && this.customerRolePermission.companyId !==0){            
          this.companyOption = this.companyOption.filter(option=>option.id == this.customerRolePermission.companyId && option.id !==0)        
        }
      }

      if (result.data.data.length > 0) {
        let item = this.companyOption[0];
        this.companySelected = item;
        this.onChangeCustomerCompany(item);
      } else {
        this.companySelected = {};
      }
    },

    async getCustomerSubCompany(companyObj) {
      const result = await CustomerService.GetCustomerSubCompanyList(
        companyObj.id
      );
      
       this.subCompanyOption = [{id:0, subCompanyName:"==== ทั้งหมด ===="}, ...result.data.data]; 

      //IF Have Role Permission For Customer
      if(this.customerRolePermission){        
        if(Object.keys(this.customerRolePermission).length > 0 && this.customerRolePermission.subCompanyId !==0){     
          this.subCompanyOption = this.subCompanyOption.filter(option=>option.id == this.customerRolePermission.subCompanyId && option.id !==0)  
        }
      }

      if (result.data.data.length > 0) {
        let item = this.subCompanyOption[0];
        this.subCompanySelected = item;
        this.onChangeCustomerSubCompany(item);
      } else {
        this.subCompanySelected = {};
      }
    },

    async getCustomerBranch(subCompanyObj) {
      const result = await CustomerService.GetCustomerBranchList(
        subCompanyObj.id
      );
      
      this.branchOption = [{id:0, branchName:"==== ทั้งหมด ===="},... result.data.data];
      
      if (result.data.data.length > 0) {
        this.branchSelected = this.branchOption[0];
      } else {
        this.branchSelected = {};
      }
    },
    onChangeCustomerOwner(item) {
      this.getCustomerCompany(item);
    },
    onChangeCustomerCompany(item) {
      this.getCustomerSubCompany(item);
    },
    onChangeCustomerSubCompany(item) {
      this.getCustomerBranch(item);
    },
buildFieldExcel() {
  let obj = {};
  Field.forEach((f) => {
    if (f.key == "openTicketDate") {
      obj[f.label] = {
        field: f.key, 
        align: 'center', // align center
        callback: (value) => {
          return this.showDate(value, "original");
        }
      };
    } else if (f.key == "closeTicketDate") {
      obj[f.label] = {
        field: f.key,
        align: 'center', // align center
        callback: (value) => {
           return this.showDate(value, "original");
        }
      };  
    } else {
      obj[f.label] = {
        field: f.key,
        align: 'center' // align center
      };
    }
  });

  this.json_fields = obj; 
},
    showDate(date, type="") {
      
      if(!date){
        return ""
      }
      if (type == "date") {
        return moment(date).format("DD-MM-yyyy");
      }

      if(type=="original"){
        return moment(date).subtract(7, 'h').format("DD-MM-yyyy HH:mm:ss") || "-";
      }

      if(type=="subtract"){
        return moment(date).subtract(7, 'h')
      }

      return moment(date).format("DD-MM-yyyy HH:mm:ss");
    },
    clearSearch(){
      this.dateStart = ""
      this.dateEnd = ""
      this.employeeSelected = { fullname: "เลือกทั้งหมด", userId: 0 }
      this.ticketStatusSelected = { id: 0, title: "เลือกทั้งหมด", value: 0 }
      this.ownerSelected = { ownerName: "เลือกทั้งหมด", id: 0 }
      this.companySelected = {}
      this.subCompanySelected = {}
      this.branchSelected =  {}
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  mounted(){
    
  }
};
</script>
<style scoped>
.table-responsive {
  overflow: none !important;
}
</style>
